<template>
  <div class="bg">
    <div class="txt">
        <p class="p1">CHARACTERS</p>
        <p class="p2">INHERIT THE WILL OF FIRE</p>
        <!-- <img class="mline" src="@/assets/images/line-white.png" alt=""> -->
    </div>
    <img class="txt_bg" src="@/assets/images/partner_name_bg.png" alt="">
    <div class="p_name_txt">{{petName}}</div>
    <img class="p_bg" src="@/assets/images/partner_pet_bg.png" alt="">
    <img class="pet" :src='petUrl' alt="">
    <div class="btn">
        <img src="@/assets/images/choose_btn_l.png" alt="" @click="left">
        <img src="@/assets/images/choose_btn_r.png" alt="" @click="right">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PetInfo',
  components: {
  },

  data() {
    return {
        pets: ['https://cdn.amusgame.net/game/4/0/4351265899124097132.png','https://cdn.amusgame.net/game/3/0/4351265899124097133.png','https://cdn.amusgame.net/game/14/0/4351265899124097134.png','https://cdn.amusgame.net/game/1/0/4351265899124097135.png'],
        petNames: ['NARUTO','TSUNADE','OBITO','TOBIRAMA'],
        // petNames: ['CHARIZARD','GRENINJA','KYOGRE'],
        // petNames: ['리자몽','개굴닌자','가이오가'],
        petUrl: '',
        petName: '',
        pIndex: 0,
    }
  },

  created() {
    this.petUrl = this.pets[this.pIndex];
    this.petName = this.petNames[this.pIndex];
  },

  methods: {
      left() {
          if (this.pIndex == 0) {
              this.pIndex = this.pets.length-1;
          } else {
              this.pIndex --;
          }
          this.petUrl = this.pets[this.pIndex];
          this.petName = this.petNames[this.pIndex];
      },
      right() {
          if (this.pIndex == (this.pets.length-1)) {
              this.pIndex = 0;
          } else {
              this.pIndex ++;
          }
          this.petUrl = this.pets[this.pIndex];
          this.petName = this.petNames[this.pIndex];
      },
  }

}
</script>

<style lang="less" scoped>

.bg {
    background: url(https://cdn.amusgame.net/game/14/0/4351265899124097140.png) center center no-repeat;
    width: 100%;
    height: 318px;
    overflow: hidden;

    .txt {
        color: white;
        font-family: 'din-bold';
        text-align: center;

        .p1 {
            font-size: 25px;
            padding-top: 29px;
            margin-bottom: 8px;
        }
        .p2 {
            font-size: 12px;
        }
        .mline {
            width: calc( 100vw - 18px - 72px);
            height: 4.5vw;
            margin-top: 5px;
        }
    }

    .txt_bg {
        margin-top: 32px;
        width: 156.43px;
        height: 47.57px;
    }
    .p_name_txt {
        width: 156.43px;
        height: 35px;
        margin-top: -35px;
        color: black;
        font-size: 25px;
        text-align: center;
        font-family: 'din-bold';
    }
    .p_bg {
        width: 66.8vw;
        height: 25.71vw;
        margin-left: 48vw;
    }
    .pet {
        width: 32.8vw;
        height: 42.4vw;
        margin-left: 55vw;
        margin-top: -35vw;
    }
    .btn {
        padding-left: 10.7vw;
        margin-top: -18vw;
        img {
            width: 24px;
            height: 24px;
            margin-right: 24px;
        }
    }

}

</style>