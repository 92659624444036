import { render, staticRenderFns } from "./PetInfo.vue?vue&type=template&id=6758a0b2&scoped=true&"
import script from "./PetInfo.vue?vue&type=script&lang=js&"
export * from "./PetInfo.vue?vue&type=script&lang=js&"
import style0 from "./PetInfo.vue?vue&type=style&index=0&id=6758a0b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6758a0b2",
  null
  
)

export default component.exports